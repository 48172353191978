.App {
  text-align: center;
  padding: 20px;
  position: relative;
}

.App-logo {
  position: absolute;
  height: 40vmin;
  top: 20px;
  left: 20px;
  /* margin-bottom: 20px;
  pointer-events: none;
  transform: translateX(-50%);
  animation: none; */
}
h1 {
  margin-bottom: 30px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

}

labels {
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="date"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button[type="submit"] {
  padding: 10px 20px;
  background-color: orange;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

h2 {
  margin-bottom: 20px;
  color: orange;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 15px;
  border-bottom: 1px solid orange;
  padding-bottom: 10px;
}

.search-form {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-term {
  flex: 1;
  padding: 10px;
  margin-right: 10px;
}

button[types="search"] {
  padding: 10px 20px;
  background-color: orange;
  color: #fff;
  border: none;
  cursor: pointer;
}

h3 {
  margin-top: 20px;
  color: orange;
}

@media screen and (max-width: 768px) {
  
} 
  


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: relative;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
